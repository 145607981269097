<template>
  <div id="other-video">
    <div class="card">
      <span>{{title}}</span>
    </div>
    <div class="video gn-rela">
      <template v-if="list.length > 6">
        <div class="left-arrow gn-abso" @click="toggleVideo('left')">
          <a-icon type="left" :style="{ color: '#cccccc', fontSize: '40px' }" />
        </div>
        <div class="right-arrow gn-abso" @click="toggleVideo('right')">
          <a-icon type="right" :style="{ color: '#cccccc', fontSize: '40px' }" />
        </div>
      </template>

      <swiper ref="swiper" :key="updateKey" :options="swiperOption">
        <swiper-slide
          class="video-list"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="video-item" @click="changeVideo(item)">
            <div class="img gn-full-x">
              <img class="gn-full" :src="item.imgs" />
            </div>
            <div class="certificate gn-box-border gn-line-1">{{item.name}}</div>
          </div>
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      updateKey: +new Date(),
      swiperOption: {
        observer: true,
        slidesPerView: 6,
        spaceBetween: 40,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // },
        // 设置自动轮播
        // autoplay: {
        //   delay: 4000, // 5秒切换一次
        //   // 用户操作swiper以后，是否禁止autoplay.默认为true中止
        //   disableOnInteraction: false
        // },
        // 设置轮播可循环
        loop: false
      },
      advisory_type: ''
    }
  },
  created() {
    const { type } = this.$route.query
    this.advisory_type = type
  },
  mounted () {
    this.resetBannerNum()
    // 1-首页轮播图 5-手机版轮播图
    window.addEventListener('resize', this.resizePage)

    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener('resize', this.resizePage)
    })
  },
  computed: {
    title() {
      let text = ''

      if (this.advisory_type === '3') {
        text = '相关视频'
      } else if (this.advisory_type === '4') {
        text = '使用说明'
      }

      return text
    }
  },
  methods: {
    // 页面大小改变
    resizePage () {
      this.resetBannerNum()
      this.getDetail(this.IS_LARGE_SCREEN ? '1' : '5')
    },
    // 重置轮播图一次性的显示数量
    resetBannerNum () {
      if (window.document.body.clientWidth >= 992) {
        this.swiperOption.slidesPerView = 6
      } else if (window.document.body.clientWidth >= 768) {
        this.swiperOption.slidesPerView = 4
      } else if (window.document.body.clientWidth >= 576) {
        this.swiperOption.slidesPerView = 3
      } else {
        this.swiperOption.slidesPerView = 1
      }
      this.updateKey = +new Date()
    },
    changeVideo (item) {
      if (item.file || item.video) {
        this.$emit('change', {
          file: item.file || item.video,
          name: item.name || item.title
        })
        window.scrollTo(0, 0)

        return
      }

      this.$message.error('暂无视频播放源')
    },
    toggleVideo(arrowDir) {
      if (arrowDir === 'left') {
        this.$refs.swiper.$swiper.slidePrev()
      } else {
        this.$refs.swiper.$swiper.slideNext()
      }
    }
  }
}
</script>

<style lang='scss' scoped>
#other-video{
  .card{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;

    span{
      display: inline-block;
      font-size: 34px;
      font-weight: 600;
      text-align: center;
      padding: 20px 0px 14px 0px;
      color: #333333;
      border-bottom: 2px solid #00A0AC;
      box-sizing: border-box;
    }
  }
  .video {
    margin: 0 auto;
    padding-top: 50px;
    .left-arrow, .right-arrow{
      width: 20px;
      height: 40px;
      cursor: pointer;
    }
    .left-arrow{
      top: 50%;
      left: -50px;
      transform: translateY(-50%);
    }
    .right-arrow{
      top: 50%;
      right: -50px;
      transform: translateY(-50%);
    }
    .video-list {
      box-sizing: border-box;
      height: 230px;
      .video-item {
        width: 227px;
        height: 205px;
        background: #f8f8f8;
        cursor: pointer;
        .img {
          height: 143px;
        }
        .certificate {
          font-size: 18px;
          color: #333333;
          padding-left: 15px;
          padding-top: 12px;
        }
      }
    }
  }
}
</style>